<style lang="scss" scoped></style>

<template>
	<div class="employer">
		<header-nav>{{ navText }}</header-nav>

		<protect-limit :productId="productId" :planId="planId">
			<span slot="title">保险起止期</span>
		</protect-limit>
		<split-line></split-line>

		<insure-form :isCompany="true"></insure-form>
		<split-line></split-line>

		<!-- 标的信息 -->
		<subject-form peopleNum="" businessAddress=""></subject-form>
		<split-line></split-line>

		<!-- 被保人信息 -->
		<assured-form :sameInsure="true" :isCompany="true"></assured-form>
		<split-line></split-line>

		<!-- 联系人 -->
		<link-man></link-man>
		<split-line></split-line>

		<!-- 条款 -->
		<clause v-model="check" :productId="productId"></clause>

		<price-buy-btn :price="price" @buy="buy"></price-buy-btn>
	</div>
</template>

<script>
import { Toast } from 'vant';
import splitLine from '../components/splitLine.vue';
import protectLimit from '../components/protectLimit.vue';
import insureForm from '../components/insureForm.vue';
import subjectForm from '../components/subjectForm.vue';
import assuredForm from '../components/assuredForm.vue';
import clause from '../components/clause.vue';
import linkMan from '../components/linkMan.vue';
import headerNav from '../components/headerNav.vue';

export default {
	name: 'employer', // 建筑施工企业雇主责任险
	components: {
		splitLine,
		protectLimit,
		insureForm,
		subjectForm,
		assuredForm,
		clause,
		linkMan,
		headerNav,
		[Toast.name]: Toast,
	},
	data() {
		return {
			navText: '建筑施工企业雇主责任险-计划一',
			productId: '',
			planId: '',

			scrollY: 0,

			price: 0,
			check: false,
		};
	},
	created() {
		this.init();
	},
	beforeRouteLeave(to, from, next) {
		this.scrollY = document.querySelector('html').scrollTop;
		next();
	},
	beforeRouteEnter(to, from, next) {
		next(vm => {
			vm.$nextTick(() => {
				document.querySelector('html').scrollTop = vm.scrollY;
			});
		});
	},
	methods: {
		back() {
			this.$router.push({ name: 'product', query: { productId: this.productId } });
		},

		init() {
			let query = this.$route.query;
			this.productId = query.productId;
			this.planId = query.plainId;
		},

		buy() {
			if (!this.check) {
				Toast('请先勾选条款');
				return false;
			}
		},
	},
};
</script>
